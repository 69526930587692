import React from 'react'
import {graphql,StaticQuery} from "gatsby"
import Layout from '../components/layout'
import Container from '../components/container'
import InnerHTML from 'html-react-parser'
import Metatags from '../components/Metatags'

const PrivacyPolicy = () => (
    <Layout>
       <Metatags
            title="Optinly | Privacy Policy"
            description = "Discover how Optinly protects your data. Privacy Policy details data collection, usage, GDPR, and CCPA rights."
            keywords= {[`exit popup`, `popup maker`, `free exit popup generator`, `email optins Features Page`]}
            url = "https://www.optinly.com"
            pathname = "/privacy-policy/"
        />
        <div className="breadcrumb_top nnil">
          <div className="container">
              <div className="row justify-content-center align-items-center">
                  <div className="col-md-6">
                      <h2>Privacy policy</h2>
                      <p>Please fill-in and submit the form with your request.</p>
                  </div>
              </div>
          </div>
        </div>

        <Container type="s">
            <div className="legal-info">
              <StaticQuery
              query={PrivacyPolicyQuery}
              render={data => {
                return (
                    <Container type="s">
                      {InnerHTML(data.wpPage.content)}
                    </Container>
                )
                }}
              />
            </div>
        </Container>
    </Layout>
)

const PrivacyPolicyQuery = graphql`
query PrivacyPolicyQuery {
  wpPage(slug: {eq: "privacy-policy-latest"}) {
    title
    uri
    content
  }
}
`

export default PrivacyPolicy
